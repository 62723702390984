import './CellTwoCol.scss';

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useModalContext } from '../../../context/ModalContext';
import {
  CreateThreadMutationFn,
  MoveScaleMapTaskMutationFn,
  ScaleMapColumnStatisticsType,
} from '../../../graphql/generated/graphql';
import { useShowStatus2Filter } from '../../../hooks';
import { ModalStatuses } from '../../../types/enums';
import { textSlice } from '../../../utils';
import { EditIcon, EditIcon2, PlusIcon2, PlusIcons, TredLinkIcon } from '../../icons';

type CellTwoColType = {
  content?: any;
  fatherId: number;
  createScaleMapTaskMutation: (value: any) => void;
  setSelectTwoCol?: (value: number) => void;
  selectTwoCol?: number;
  selectFirstCol?: number;
  updateScaleMapTaskMutation: (value: any) => void;
  deleteScaleMapTaskMutation: (value: any) => void;
  index: number;
  statictic: ScaleMapColumnStatisticsType | undefined;
  fatherArray: [number | null];
  filterStatus: null | number;
  isLineSuccesses: boolean;
  createThreadMutation: CreateThreadMutationFn;
  moveScaleMapTaskMutation: MoveScaleMapTaskMutationFn;
};

export const CellTwoCol = ({
  content,
  fatherId,
  createScaleMapTaskMutation,
  selectTwoCol,
  setSelectTwoCol,
  selectFirstCol,
  updateScaleMapTaskMutation,
  deleteScaleMapTaskMutation,
  index,
  statictic,
  fatherArray,
  filterStatus,
  isLineSuccesses,
  createThreadMutation,
  moveScaleMapTaskMutation,
}: CellTwoColType) => {
  const { fileId } = useParams();
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const navigate = useNavigate();

  const currentFather = content?.map((e: any) => e?.scaleMapTaskId);
  const selectIsFather = fatherArray.indexOf(selectFirstCol as any) !== -1;

  const { showLocalStatus2Filter, handleSetSowLocalStatus2Filter } = useShowStatus2Filter(
    { filterStatus },
  );

  const showState2 =
    content?.length >= 1 && currentFather?.indexOf(selectFirstCol as any) !== -1;
  const showState3 =
    fatherArray.indexOf(selectFirstCol as any) === -1 && content?.length >= 1;

  const showState4 =
    !fatherId ||
    (fatherArray.indexOf(selectFirstCol as any) === -1 && content?.length === 0);

  const editTaskHeader = ({
    task,
  }: {
    task: { name: string; status: number; id: number; scaleMapTaskId: number };
  }) => {
    handleSetModalStatus(ModalStatuses.ADD_FILE_TASK_STATUS_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: { name: string; status: number }) => {
          updateScaleMapTaskMutation({
            variables: {
              input: {
                id: task?.id,
                name: value?.name,
                scaleMapFileId: parseInt(fileId as string),
                scaleMapTaskId: task?.scaleMapTaskId,
                statusId: value?.status,
              },
            },
          });
        },
      },
      {
        key: 'onCallbackDelete',
        value: () => {
          deleteScaleMapTaskMutation({
            variables: {
              id: task?.id,
            },
          });
        },
      },
      {
        key: 'initialValues',
        value: {
          name: task?.name,
          status: task?.status,
          fileId,
          scaleMapTaskId: task?.scaleMapTaskId,
        },
      },
      {
        key: 'onCallbackMove',
        value: (parentScaleMapTaskId: number) => {
          moveScaleMapTaskMutation({
            variables: {
              parentScaleMapTaskId: parentScaleMapTaskId,
              scaleMapTaskId: task?.id,
            },
          });
        },
      },
      {
        key: 'col',
        value: 'col2',
      },
    ]);
  };

  if (showLocalStatus2Filter) {
    return (
      <div
        className={`CellTwoCol__state2  ${isLineSuccesses && 'CellTwoCol__state2-black'}`}
        onClick={(e) => {
          e.stopPropagation();
          handleSetSowLocalStatus2Filter();
        }}
      >
        {content?.map((e: any, index: number) => {
          return (
            <div
              className={`${
                selectTwoCol === e.id ? 'CellTwoCol__state2-select' : ''
              } CellTwoCol__state2-element `}
              key={index}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span className='CellTwoCol__status-wrapper'>
                <div
                  className='CellTwoCol__status'
                  style={{
                    backgroundColor: e?.status?.color,
                  }}
                ></div>
                {textSlice({ text: e?.name, count: 30 })}
              </span>

              <div className='CellTwoCol__state2-icons'>
                <EditIcon
                  color='black'
                  width={14}
                  height={14}
                  onClick={() => {
                    editTaskHeader({
                      task: {
                        status: e?.status?.id,
                        name: e?.name,
                        id: e?.id,
                        scaleMapTaskId: e?.scaleMapTaskId,
                      },
                    });
                  }}
                />
              </div>
            </div>
          );
        })}
        <div className='CellTwoCol__state2-empty'></div>
      </div>
    );
  }

  if (showState4) {
    return null;
  }

  if (showState3) {
    return (
      <div
        className={`CellTwoCol__state3 ${isLineSuccesses && 'CellTwoCol__state3-black'}`}
        onClick={() => {
          handleSetSowLocalStatus2Filter();
        }}
      >
        <div
          style={{
            backgroundColor: isLineSuccesses ? '#303030' : statictic?.statusTasks?.color,
            width: `${statictic?.statusTasksPercent}%`,
            borderRadius: statictic?.statusTasksPercent === 100 ? '5px' : undefined,
          }}
          className='CellTwoCol__state3-percent'
        ></div>
        <div className='CellTwoCol__state3-gray'></div>
        <span className='CellTwoCol__state3-name'>{statictic?.statusTasksPercent}%</span>
      </div>
    );
  }

  const addTaskHeader = ({ scaleMapTaskId }: any) => {
    handleSetModalStatus(ModalStatuses.ADD_FILE_TASK_STATUS_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: { name: string; status: number }) => {
          createScaleMapTaskMutation({
            variables: {
              input: {
                name: value?.name,
                scaleMapFileId: parseInt(fileId as string),
                scaleMapTaskId: scaleMapTaskId,
                statusId: value?.status,
                column: 2,
                row: index + 1,
              },
            },
          });
        },
      },
      {
        key: 'initialValues',
        value: undefined,
      },
      {
        key: 'col',
        value: 'col2',
      },
    ]);
  };

  if (showState2) {
    return (
      <div
        className={`CellTwoCol__state2 ${isLineSuccesses && 'CellTwoCol__state2-black'}`}
      >
        {content?.map((e: any, index: number) => {
          const select = selectTwoCol === e.id;

          if (e?.scaleMapTaskId !== selectFirstCol) return null;
          return (
            <div
              className={`${
                select ? 'CellTwoCol__state2-select' : ''
              } CellTwoCol__state2-element `}
              key={index}
              onClick={() => {
                setSelectTwoCol && setSelectTwoCol(e?.id as number);
              }}
            >
              <div className='CellTwoCol__status-wrapper'>
                <div
                  className='CellTwoCol__status'
                  style={{
                    backgroundColor: e?.status?.color,
                  }}
                ></div>
                {select ? e?.name : textSlice({ text: e?.name, count: 30 })}
              </div>

              <div className='CellTwoCol__state2-icons'>
                {e?.thread?.id && (
                  <TredLinkIcon
                    className='CellTwoCol__editIcons'
                    onClick={() => {
                      navigate('/tread/' + e?.thread?.id);
                    }}
                  />
                )}
                {!e?.thread?.id && (
                  <PlusIcon2
                    className='CellTwoCol__state2-plus'
                    onClick={() => {
                      handleSetModalStatus(ModalStatuses.SCALE_TO_TREAD_MODAL);
                      handleChangeModalParameters([
                        {
                          key: 'onCallback',
                          value: (value: any) => {
                            const { data } = value.variables;
                            createThreadMutation({
                              variables: {
                                data: {
                                  cover: data?.cover,
                                  threadBackgroundId: data?.threadBackgroundId,
                                  scaleMapTaskId: data?.id,
                                  performers: data?.performers,
                                  tagsIds: data?.tagsIds,
                                  scaleMapTask: {
                                    name: data?.scaleMapTask?.name,
                                  },
                                },
                              },
                            });
                          },
                        },
                        {
                          key: 'initialValues',
                          value: {
                            id: e?.id,
                            name: e?.name,
                            selectFile: parseInt(fileId as any),
                          },
                        },
                      ]);
                    }}
                  />
                )}
                <EditIcon2
                  onClick={() =>
                    editTaskHeader({
                      task: {
                        status: e?.status?.id,
                        name: e?.name,
                        id: e?.id,
                        scaleMapTaskId: e?.scaleMapTaskId,
                      },
                    })
                  }
                />
              </div>
            </div>
          );
        })}
        <div
          onClick={() => addTaskHeader({ scaleMapTaskId: selectFirstCol })}
          className='CellTwoCol__state2-button'
          style={{
            justifyContent: 'center',
          }}
        >
          <div className='CellTwoCol__child'>
            <PlusIcons color='#149939' width='24' height='24' />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className='CellTwoCol__state1'
      onClick={() =>
        addTaskHeader({ scaleMapTaskId: selectIsFather ? selectFirstCol : fatherId })
      }
    >
      <div className='CellTwoCol__child'>
        <PlusIcons color='#149939' width='24' height='24' />
      </div>
    </div>
  );
};
