import './FileList.scss';

import React, { useEffect } from 'react';

import {
  CreateThreadMutationFn,
  MoveScaleMapTaskMutationFn,
  ScaleMapColumnStatisticsType as ScaleMapColumnStatisticsTypeType,
} from '../../graphql/generated/graphql';
import { CellFirstCol } from './CellFirstCol/CellFirstCol';
import { CellThreeCol } from './CellThreeCol/CellThreeCol';
import { CellTwoCol } from './CellTwoCol/CellTwoCol';

type FileListType = {
  createScaleMapTaskMutation: (value: any) => void;
  updateScaleMapTaskMutation: (value: any) => void;
  deleteScaleMapTaskMutation: (value: any) => void;
  moveScaleMapTaskToRowMutation: (value: any) => void;
  selectFirstCol: number;
  setSelectFirstCol: (value: number) => void;
  selectTwoCol: number;
  setSelectTwoCol: (value: number) => void;
  selectThreeCol: number;
  setSelectThreeCol: (value: number) => void;
  scaleMapColumnStatistics: [ScaleMapColumnStatisticsTypeType] | undefined;
  mergedColumnStatistics: [ScaleMapColumnStatisticsTypeType] | undefined;
  filterStatus: number | null;
  setFilterStatus: (value: number) => void;
  hideSuccess: boolean;
  selectTask: any;
  setSelectTask: (value: number | null) => void;
  hideSuccessInner: boolean;
  createThreadMutation: CreateThreadMutationFn;
  newArr: any;
  moveScaleMapTaskMutation: MoveScaleMapTaskMutationFn;
};

export const FileList = ({
  createScaleMapTaskMutation,
  updateScaleMapTaskMutation,
  deleteScaleMapTaskMutation,
  moveScaleMapTaskToRowMutation,
  selectTwoCol,
  setSelectTwoCol,
  selectThreeCol,
  setSelectThreeCol,
  selectFirstCol,
  setSelectFirstCol,
  scaleMapColumnStatistics,
  mergedColumnStatistics,
  filterStatus,
  setFilterStatus,
  hideSuccess,
  selectTask,
  setSelectTask,
  hideSuccessInner,
  createThreadMutation,
  newArr,
  moveScaleMapTaskMutation,
}: FileListType) => {
  useEffect(() => {
    setSelectThreeCol(0);
    setSelectTwoCol(0);
  }, [filterStatus]);
  useEffect(() => {
    setSelectThreeCol(0);
    setSelectTwoCol(0);
  }, [selectFirstCol]);
  useEffect(() => {
    setSelectThreeCol(0);
  }, [selectTwoCol]);

  const handleMoveScaleMapTaskToRowMutation = (index: any, length: any) => {
    moveScaleMapTaskToRowMutation({
      variables: {
        id: selectTask,
        row: index,
        order: length ? length : 0,
        tags: [],
      },
    });
    setSelectTask(null);
  };

  return (
    <div className='file-list__table'>
      {newArr.map((e: any, index: number) => {
        const rowStatistic = scaleMapColumnStatistics?.filter((e) => e.row === index + 1);
        const rowMergeStatistic = mergedColumnStatistics?.filter(
          (e) => e.row === index + 1,
        );
        const isLineSuccesses =
          e?.col1?.every(
            (element: any) => element.isNestingAllTasksWithCompleted === true,
          ) && e?.col1?.length > 0;
        // easy hide empty slot fix
        if (e?.col1?.length === 0 && index + 1 < newArr?.length) return null;

        if (hideSuccess && isLineSuccesses) return null;
        return (
          <div className='file-list__row' key={index}>
            <div className='file-list__column' style={{ position: 'relative' }}>
              {selectTask && (
                <div
                  className='file-list__selector'
                  onClick={() => {
                    handleMoveScaleMapTaskToRowMutation(index + 1, e?.col1?.length);
                  }}
                ></div>
              )}

              <CellFirstCol
                content={e?.col1?.sort((a: any, b: any) => {
                  return a.order - b.order;
                })}
                createScaleMapTaskMutation={createScaleMapTaskMutation}
                selectFirstCol={selectFirstCol}
                setSelectFirstCol={setSelectFirstCol}
                updateScaleMapTaskMutation={updateScaleMapTaskMutation}
                deleteScaleMapTaskMutation={deleteScaleMapTaskMutation}
                index={index}
                setFilterStatus={setFilterStatus}
                isLineSuccesses={isLineSuccesses}
                setSelectTask={setSelectTask}
                selectTask={selectTask}
                hideSuccessInner={hideSuccessInner}
              />
            </div>
            <TwoColShowStatus
              e={e}
              createScaleMapTaskMutation={createScaleMapTaskMutation}
              setSelectTwoCol={setSelectTwoCol}
              selectFirstCol={selectFirstCol}
              selectTwoCol={selectTwoCol}
              updateScaleMapTaskMutation={updateScaleMapTaskMutation}
              deleteScaleMapTaskMutation={deleteScaleMapTaskMutation}
              index={index}
              rowStatistic={rowStatistic}
              filterStatus={filterStatus}
              isLineSuccesses={isLineSuccesses}
              createThreadMutation={createThreadMutation}
              moveScaleMapTaskMutation={moveScaleMapTaskMutation}
              selectThreeCol={selectThreeCol}
              setSelectThreeCol={setSelectThreeCol}
              rowMergeStatistic={rowMergeStatistic}
            />
          </div>
        );
      })}
    </div>
  );
};

type TwoColShowStatusType = {
  e: any;
  createScaleMapTaskMutation: (value: any) => void;
  setSelectTwoCol: (value: any) => void;
  selectFirstCol: number;
  selectTwoCol: number;
  updateScaleMapTaskMutation: (value: any) => void;
  deleteScaleMapTaskMutation: (value: any) => void;
  index: number;
  rowStatistic: any;
  filterStatus: number | null;
  isLineSuccesses: any;
  createThreadMutation: CreateThreadMutationFn;
  moveScaleMapTaskMutation: MoveScaleMapTaskMutationFn;
  selectThreeCol: any;
  setSelectThreeCol: any;
  rowMergeStatistic: any;
};

const TwoColShowStatus = ({
  e,
  createScaleMapTaskMutation,
  setSelectTwoCol,
  selectFirstCol,
  selectTwoCol,
  updateScaleMapTaskMutation,
  deleteScaleMapTaskMutation,
  index,
  rowStatistic,
  filterStatus,
  isLineSuccesses,
  createThreadMutation,
  moveScaleMapTaskMutation,
  selectThreeCol,
  setSelectThreeCol,
  rowMergeStatistic,
}: TwoColShowStatusType) => {
  const fatherArray = e?.col1?.map((e: any) => e.id);
  const selectIsFather = fatherArray.indexOf(selectFirstCol as any) !== -1;
  const percent: any = rowMergeStatistic[0]?.statusTasksPercent;
  const color: any = rowMergeStatistic[0]?.statusTasks?.color;

  if (!selectIsFather && !filterStatus)
    return (
      <div
        className='file-list__column'
        style={{
          width: '70%',
        }}
      >
        {percent && (
          <div
            className={`CellTwoCol__state3 ${
              isLineSuccesses && 'CellTwoCol__state3-black'
            }`}
          >
            <div
              style={{
                backgroundColor: isLineSuccesses ? '#303030' : color,
                width: `${percent}%`,
                borderRadius: percent === 100 ? '5px' : undefined,
              }}
              className='CellTwoCol__state3-percent'
            ></div>
            <div className='CellTwoCol__state3-gray'></div>
            <span className='CellTwoCol__state3-name'>{percent}%</span>
          </div>
        )}
      </div>
    );

  return (
    <>
      <div className='file-list__column'>
        <CellTwoCol
          content={e?.col2}
          fatherId={e?.col1[0]?.id}
          createScaleMapTaskMutation={createScaleMapTaskMutation}
          setSelectTwoCol={setSelectTwoCol}
          selectFirstCol={selectFirstCol}
          selectTwoCol={selectTwoCol}
          updateScaleMapTaskMutation={updateScaleMapTaskMutation}
          deleteScaleMapTaskMutation={deleteScaleMapTaskMutation}
          index={index}
          statictic={rowStatistic?.find((e: any) => e.column === 2)}
          fatherArray={e?.col1?.map((e: any) => e.id)}
          filterStatus={filterStatus}
          isLineSuccesses={isLineSuccesses}
          createThreadMutation={createThreadMutation}
          moveScaleMapTaskMutation={moveScaleMapTaskMutation}
        />
      </div>
      <div className='file-list__column'>
        <CellThreeCol
          content={e?.col3}
          selectTwoCol={selectTwoCol}
          createScaleMapTaskMutation={createScaleMapTaskMutation}
          selectThreeCol={selectThreeCol}
          setSelectThreeCol={setSelectThreeCol}
          updateScaleMapTaskMutation={updateScaleMapTaskMutation}
          deleteScaleMapTaskMutation={deleteScaleMapTaskMutation}
          index={index}
          statictic={rowStatistic?.find((e: any) => e.column === 3)}
          fatherArray={e?.col2?.map((e: any) => e.id)}
          fatherId={e?.col2[0]?.id}
          filterStatus={filterStatus}
          isLineSuccesses={isLineSuccesses}
        />
      </div>
    </>
  );
};
