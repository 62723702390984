import './File.scss';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FileList, RequestHandler } from '../../components';
import { useAuthContext, useModalContext } from '../../context';
import { ModalStatuses } from '../../types/enums';
import { useAnchorFile, useControlSelectCol, useFileModeration } from './FileHooks/';
import { FileTitle } from './FileTitle';

export const File = () => {
  const navigate = useNavigate();
  const { currentUserData } = useAuthContext();
  const { fileId } = useParams();
  const location = useLocation();
  const selfLocation = location?.pathname?.includes('/file/self/');

  const [hideSuccess, setHideSuccess] = useState(true);
  const [hideSuccessInner, setHideSuccessInner] = useState(true);

  useEffect(() => {
    if (!hideSuccess) {
      setHideSuccessInner(false);
    }
  }, [hideSuccess]);

  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const {
    createScaleMapTaskMutation,
    deleteScaleMapFileMutation,
    updateScaleMapFileMutation,
    deleteScaleMapTaskMutation,
    updateScaleMapTaskMutation,
    moveScaleMapTaskToRowMutation,
    file,
    filterStatus,
    setFilterStatus,
    createThreadMutation,
    moveScaleMapTaskMutation,
  } = useFileModeration({ fileId, navigate, selfLocation, hideSuccessInner });

  useEffect(() => {
    setHideSuccessInner(file?.isStatus1ExcludedInFilter);
  }, [file?.isStatus1ExcludedInFilter]);

  // redirect
  useEffect(() => {
    if (!file) return;
    if (file?.isPublic) return;
    if (file?.user?.id !== currentUserData?.id && selfLocation) {
      navigate('/projectI');
    }
  }, [file]);

  const {
    selectFirstCol,
    setSelectFirstCol,
    reload,
    selectTwoCol,
    setSelectTwoCol,
    selectThreeCol,
    setSelectThreeCol,
  } = useControlSelectCol({ setFilterStatus });

  useAnchorFile({ setSelectFirstCol, file });

  // select task for moving rows
  const [selectTask, setSelectTask] = useState<number | null>(null);
  const updateFileName = () => {
    handleSetModalStatus(ModalStatuses.UPDATE_FILE_SCALE_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: { name: string; calendarTaskTextColor?: string }) => {
          updateScaleMapFileMutation({
            variables: {
              input: {
                id: `${file?.id}`,
                name: value?.name,
                projectId: file?.project?.id ? `${file?.project?.id}` : null,
                calendarTaskTextColor: value?.calendarTaskTextColor,
              },
            },
          });
        },
      },
      {
        key: 'onCallbackisArchived',
        value: () => {
          updateScaleMapFileMutation({
            variables: {
              input: {
                id: `${file?.id}`,
                isArchived: !file?.isArchived,
              },
            },
          }).then(() => {
            toast.success(file?.isArchived ? 'Убрано из архива' : 'Добавлено в архив');
          });
        },
      },
      {
        key: 'onCallbackDelete',
        value: () => {
          deleteScaleMapFileMutation({
            variables: {
              id: parseInt(file?.id as any),
            },
          });
        },
      },
      {
        key: 'onCallbackChangeFile',
        value: (value: { projectId: number }) => {
          updateScaleMapFileMutation({
            variables: {
              input: {
                id: `${file?.id}`,
                projectId: value?.projectId as any,
              },
            },
          });
        },
      },
      {
        key: 'onCallbackSleepFile',
        value: () => {
          updateScaleMapFileMutation({
            variables: {
              input: {
                id: `${file?.id}`,
                isBridgeOfSleep: !file?.isBridgeOfSleep,
              },
            },
          })
            .then(() => {
              toast.success(
                file?.isBridgeOfSleep
                  ? 'Убрано мост как “Сон”'
                  : 'Сделано мостом как “Сон”',
              );
            })
            .catch(() => {
              toast.error('Мост "Сон" уже существует');
            });
        },
      },
      {
        key: 'onCallbackPesonalFile',
        value: () => {
          updateScaleMapFileMutation({
            variables: {
              input: {
                id: `${file?.id}`,
                isPersonal: !file?.isPersonal,
              },
            },
          }).then(() => {
            toast.success(
              file?.isPersonal ? 'Файл стал публичным' : 'Файл стал персональным',
            );
          });
        },
      },
      {
        key: 'onCallbackisStatus1ExcludedInFilter',
        value: () => {
          updateScaleMapFileMutation({
            variables: {
              input: {
                id: `${file?.id}`,
                isStatus1ExcludedInFilter: !file?.isStatus1ExcludedInFilter,
              },
            },
          });
        },
      },
      {
        key: 'initialValues',
        value: {
          name: file?.name,
          projectId: file?.project?.id ? file?.project?.id : null,
          projectName: file?.project?.name,
          isArchived: file?.isArchived,
          isBridgeOfSleep: file?.isBridgeOfSleep,
          isPersonal: file?.isPersonal,
          calendarTaskTextColor: file?.calendarTaskTextColor,
          isStatus1ExcludedInFilter: file?.isStatus1ExcludedInFilter,
        },
      },
      {
        key: 'selfLocation',
        value: selfLocation,
      },
    ]);
  };

  const handleFonAndTags = () => {
    handleSetModalStatus(ModalStatuses.FILE_FON_AND_TAGS_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: any) => {
          updateScaleMapFileMutation({
            variables: {
              input: {
                id: fileId as string,
                attachedTagIds: value?.attachedTagIds?.map((e: string) => parseInt(e)),
                threadBackgroundId: value?.threadBackgroundId,
              },
            },
          });
        },
      },
      {
        key: 'fileId',
        value: parseInt(fileId as any),
      },
    ]);
  };

  const newArr: any = calcNewArray({ arr: file?.scaleMapTasks });

  const handleChangeName = ({ name, column }: any) => {
    handleSetModalStatus(ModalStatuses.CHANGE_COL_NAME_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: { name: string }) => {
          updateScaleMapFileMutation({
            variables: {
              input: {
                id: `${file?.id}`,
                columnName1: column === 1 ? value?.name : file?.columnName1,
                columnName2: column === 2 ? value?.name : file?.columnName2,
                columnName3: column === 3 ? value?.name : file?.columnName3,
              },
            },
          });
        },
      },
      {
        key: 'initialValues',
        value: {
          name: name,
        },
      },
    ]);
  };

  return (
    <div className='container'>
      <RequestHandler loading={!file}>
        <div className='file-list'>
          <div className='file-list__body'>
            <FileTitle
              updateFileName={updateFileName}
              file={file}
              setSelectTask={setSelectTask}
              setHideSuccessInner={setHideSuccessInner}
              setHideSuccess={setHideSuccess}
              reload={reload}
              hideSuccess={hideSuccess}
              hideSuccessInner={hideSuccessInner}
              filterStatus={filterStatus}
              setFilterStatus={setFilterStatus}
              newArr={newArr}
              handleFonAndTags={handleFonAndTags}
            />
            <div className='horizontal__line'></div>
            <div className='file-list__title'>
              <div
                className='file-list__title-col cssSlic'
                style={{
                  cursor: 'pointer',
                  lineHeight: '16px',
                }}
                onClick={() => {
                  handleChangeName({ name: file?.columnName1, column: 1 });
                }}
              >
                {file?.columnName1}
              </div>
              <div
                className='file-list__title-col cssSlic'
                style={{
                  cursor: 'pointer',
                  lineHeight: '16px',
                }}
                onClick={() => {
                  handleChangeName({ name: file?.columnName2, column: 2 });
                }}
              >
                {file?.columnName2}
              </div>
              <div
                className='file-list__title-col cssSlic'
                style={{
                  cursor: 'pointer',
                  lineHeight: '16px',
                }}
                onClick={() => {
                  handleChangeName({ name: file?.columnName3, column: 3 });
                }}
              >
                {file?.columnName3}
              </div>
            </div>
            <FileList
              scaleMapColumnStatistics={file?.scaleMapColumnStatistics as any}
              mergedColumnStatistics={file?.mergedColumnStatistics as any}
              createScaleMapTaskMutation={createScaleMapTaskMutation}
              updateScaleMapTaskMutation={updateScaleMapTaskMutation}
              deleteScaleMapTaskMutation={deleteScaleMapTaskMutation}
              moveScaleMapTaskToRowMutation={moveScaleMapTaskToRowMutation}
              selectTwoCol={selectTwoCol}
              setSelectTwoCol={setSelectTwoCol}
              selectThreeCol={selectThreeCol}
              setSelectThreeCol={setSelectThreeCol}
              selectFirstCol={selectFirstCol}
              setSelectFirstCol={setSelectFirstCol}
              filterStatus={filterStatus}
              setFilterStatus={setFilterStatus}
              hideSuccess={hideSuccess}
              selectTask={selectTask}
              setSelectTask={setSelectTask}
              hideSuccessInner={hideSuccessInner}
              createThreadMutation={createThreadMutation}
              newArr={newArr}
              moveScaleMapTaskMutation={moveScaleMapTaskMutation}
            />
          </div>
        </div>
      </RequestHandler>
    </div>
  );
};

const calcNewArray = ({ arr }: any) => {
  let maxRow = 0;
  arr?.map((e: any) => {
    if (maxRow < e?.row) {
      maxRow = e?.row;
    }
  });
  let newArr = new Array(maxRow + 1).fill({
    col1: [],
    col2: [],
    col3: [],
    col4: [],
  });
  newArr = newArr?.map((_, index) => {
    const col1: any = [];
    const col2: any = [];
    const col3: any = [];
    const col4: any = [];
    arr?.map((e: any) => {
      if (e?.row !== index + 1) return;
      if (e?.column === 1) {
        col1.push(e);
      }
      if (e?.column === 2) {
        col2.push(e);
      }
      if (e?.column === 3) {
        col3.push(e);
      }
      if (e?.column === 4) {
        col4.push(e);
      }
    });
    return {
      col1,
      col2,
      col3,
      col4,
    };
  });

  return newArr;
};
